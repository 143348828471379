
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();


  React.useEffect(() => {
    document.body.classList.add("bg-dark-green");
    return () => {
      document.body.classList.remove("bg-dark-green");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    let r = routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
    return r;
  };

  return (
    <>
      <div
        className="main-content pb-3"
        ref={mainContent}
      >
        <AuthNavbar />
        <div className="header bg-white-original">
          {/* <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center mb-5">
                <Col lg="5" md="6">
                  <h1 className="text-green">Welcome to LAPO!</h1>
                </Col>
              </Row>
            </div>
          </Container> */}
          {/* <div
            className="separator separator-bottom separator-skew zindex-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-dark-green"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        </div>
        {/* Page content */}
        <Container className="">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/login" />
            </Switch>
          </Row>
        </Container>
      </div>

      {/* <AuthFooter /> */}
    </>
  );
};

export default Auth;
