import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";

import { useToasts } from 'react-toast-notifications';
import { checkSpace, postMethod } from '../../services/httpServices';
import { 
  CalcMethod, CommissionType, checkTableField, changeCalMethod,
  ValidateCommission, getInitialValue, checkTableRateField,
  setCommissionValues, TableBody, checkTableAmountTypeField,
  TableCalcMethod, checkMinAmtDisable, getMinValueName
} from "services/commission-service";
import { checkDigit2Decimal, checkPercentage } from "services/util";


const TYPE = CommissionType ? CommissionType : [];
const METHOD = CalcMethod ? CalcMethod : [];
const TABLE_METHOD = TableCalcMethod ? TableCalcMethod : [];
const TABLE_BODY = TableBody ? TableBody : [];


const EditCommission = (props) => {

  const initialValue = getInitialValue(props);
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();


  /**======================== React Hooks ==========================*/

  React.useEffect(()=> {
    // const v = changeCalMethod(values, values.method);
    // changeState(v);
  }, [values.method]);


  /**======================== File Function ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   if (checkSpace(value)) {
  //     if (name == 'method') {
  //       const v = changeCalMethod(values, values.method);
  //       if (value == '1') {
  //         changeState({ ...v, [name]: value, 'amount': 0 });
  //       } else {
  //         changeState({ ...v, [name]: value});
  //       }
  //     } else if (name == "flatamount") {
  //       if (checkDigit2Decimal(value)) {
  //         changeState({ ...values, [name]: value });
  //       }
  //     } else if (name == "rate") {
  //       if (checkPercentage(value)) {
  //         changeState({ ...values, [name]: value });
  //       }
  //     } else if (checkTableRateField(name) && checkPercentage(value)) {
  //       changeState({...values, table: {...values.table, [name]: value}});
  //     } else if (checkTableField(name) && checkDigit2Decimal(value)) {
  //       changeState({...values, table: {...values.table, [name]: value}});
  //     } else {
  //       changeState({ ...values, [name]: value });
  //     }
  //   }
  // };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name == 'method' && value == '1') {
        changeState({ ...values, [name]: value, 'amount': 0 });
      } else if (name == "flatamount") {
        if (checkDigit2Decimal(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name == "rate") {
        if (checkPercentage(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (checkTableRateField(name)) {
        if (checkPercentage(value)) {
          changeState({ ...values, table: { ...values.table, [name]: value } });
        }
      } else if (checkTableField(name)) {
        if (checkDigit2Decimal(value)) {
          // changeState({ ...values, table: { ...values.table, [name]: value } });
          let str = getMinValueName(name);
          let obj = str ? { ...values.table, [name]: value, [str]: value } : 
          { ...values.table, [name]: value }
          changeState({ ...values, table: obj });
        }
      } else if (checkTableAmountTypeField(name)) {
        changeState({ ...values, table: { ...values.table, [name]: value } });
      } else {
        changeState({ ...values, [name]: value });
      }
    }
  };

  const changeState = (obj) => {
    setValues(obj);
  };

  const closeModal = () => {
    props.closeModal();
  }

  const getTableTypeValue = (v, i) => {
    let str = v + i;
    return values.table[str];
  }

  const checkDisable = (i) => {
    let str = 'calcMethod' + i;
    if (values.table[str] == "") {
      return true;
    } else {
      return false;
    }
  }

  const showAmtOrRate = (i) => {
    let str = 'calcMethod' + i;
    if (values.table[str] == "1") {
      return (
        <td
        className="commission-table-cls-2 "
      >
        <InputGroup className="search-cls">

          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              Le
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            name={`commissionAmt${i}`}
            value={getTableTypeValue('commissionAmt', i)}
            onChange={handleInputChange}
          />
        </InputGroup>
       
      </td>
      )
    } else if (values.table[str] == "2") {
      return (
        <>
          <td 
            className="commission-table-cls"
          >
            <InputGroup className="search-cls">
              <Input
                type="text"
                name={`commissionRate${i}`}
                value={getTableTypeValue('commissionRate', i)}
                onChange={handleInputChange}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  (&nbsp; <i className="fas fa-percent fa-xs" /> &nbsp;)
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </td>
          
        </>
        

      )
    }
  }



  /**======================== Validation Function  ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = ValidateCommission(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      var data = setCommissionValues(values);
      data['commissionId'] = values.id;
      data['CommissionValue'] = Number(values.rate);
      let errorMsg = '';
      setLoading(true);
      postMethod('/api/commission/update', JSON.stringify(data), 'post')
      .then(res => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess('Saved Successfuly');
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || 'Error while saving';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in saving';
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        errorMsg = 'Internal Server Error';
        notifyFailed(errorMsg);
      });
    }
  }




  /**======================== API Calls ==========================*/



  
  return (
    <>
      <div className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit Commission</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Commission Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.name && <div className="text-left text-danger">
                            <small>* {errors.name}</small>
                          </div>}
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-type"
                            className="form-control-label">
                            Commission Type
                          </label>
                          <Input
                            type="select"
                            id="input-type"
                            className="form-control-alternative"
                            name="type"
                            value={values.type}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option key="" value="">-----Select Commission Type -------</option>
                            {TYPE.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.type && <div className="text-left text-danger">
                            <small>* {errors.type}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-type"
                            className="form-control-label">
                            Calculation Method
                          </label>
                          <Input
                            type="select"
                            id="input-method"
                            className="form-control-alternative"
                            name="method"
                            value={values.method}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">-----Select Calculation Method -------</option>
                            {METHOD.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.method && <div className="text-left text-danger">
                            <small>* {errors.method}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      {values.method && values.method == '1' &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-amount"
                            >
                              Commission Amount
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-amount"
                              placeholder="Commission Amount"
                              type="text"
                              name="amount"
                              value={values.amount}
                              disabled
                              onChange={handleInputChange}
                            />
                            {errors.amount && <div className="text-left text-danger">
                              <small>* {errors.amount}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      }


                      {values.method && values.method == '2' &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-f-amount"
                            >
                              Commission Amount
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-f-amount"
                              placeholder="Commission Amount"
                              type="text"
                              name="flatamount"
                              value={values.flatamount}
                              onChange={handleInputChange}
                            />
                            {errors.flatamount && <div className="text-left text-danger">
                              <small>* {errors.flatamount}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      }

                      
                      {values.method && (values.method == '3' || values.method == '5') &&
                        
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-rate"
                            >
                              Commission Rate
                            </label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                className="form-control-alternative"
                                id="input-rate"
                                placeholder="Commission Rate"
                                type="text"
                                name="rate"
                                value={values.rate}
                                onChange={handleInputChange}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  (&nbsp; <i className="fas fa-percent fa-xs" /> &nbsp;)
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {errors.rate && <div className="text-left text-danger">
                              <small>* {errors.rate}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      }

                    </Row>

                    {values.method && values.method == '4' &&
                      <Row>
                        <Col lg="12" style={{overflow: "auto"}}>
                          <Table>
                            <thead>
                              <tr>
                                <th className = "commission-table-cls">
                                  Commission Item</th>
                                <th className = "commission-table-cls">
                                  Transaction Band Min</th>
                                <th className = "commission-table-cls">
                                  Transaction Band Max</th>
                                <th className = "commission-table-cls">
                                  Commission Amount</th>
                                <th className = "commission-table-cls">
                                  Commission Rate</th>

                                {/* <th scope="col">Actions</th> */}
                              </tr>
                            </thead>

                            {
                              <tbody>
                                {TABLE_BODY.map((ob, i) => {
                                  return (
                                    <tr>
                                      <th className="commission-table-cls">
                                        {ob.key}
                                      </th>
                                      <td className="commission-table-cls">
                                        <Input className="search-cls"
                                          type="text"
                                          name={`minAmt${ob.count}`}
                                          // value={values.table.minAmt1}
                                          value={getTableTypeValue('minAmt', ob.count)}
                                          onChange={handleInputChange}
                                          disabled={checkMinAmtDisable(i)}
                                        />
                                      </td>

                                      <td className="commission-table-cls">
                                        <Input className="search-cls"
                                          type="text"
                                          name={`maxAmt${ob.count}`}
                                          value={getTableTypeValue('maxAmt', ob.count)}
                                          onChange={handleInputChange}
                                        />
                                      </td>


                                      <td className="commission-table-cls">
                                        <Input
                                          type="select"
                                          id="input-calc-method"
                                          className="form-control-alternative search-cls"
                                          name={`calcMethod${ob.count}`}
                                          value={getTableTypeValue('calcMethod', ob.count)}
                                          onChange={handleInputChange}
                                        >
                                          <option key="" value="">Select Type</option>
                                          {TABLE_METHOD.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                                        </Input>
                                      </td>

                                      {checkDisable(ob.count) ? <td className="commission-table-cls">
                                        <Input className="search-cls"
                                          type="text"
                                          name={`commissionRate${ob.count}`}
                                          value={getTableTypeValue('commissionRate', ob.count)}
                                          onChange={handleInputChange}
                                          disabled
                                        />
                                      </td> : showAmtOrRate(ob.count)
                                      }

                                    </tr>
                                  )

                                })
                                }
                              </tbody>
                            }

                            {/* <tbody>
                              <tr>
                                <th className = "commission-table-cls">
                                  Min. Threshold
                                </th>
                                <td className = "commission-table-cls">
                                  <Input className="search-cls"
                                      type="text" 
                                      name="minAmt1"
                                      value={values.table.minAmt1}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text"
                                      name="maxAmt1" 
                                      value={values.table.maxAmt1}
                                      onChange={handleInputChange}
                                    />
                                </td>


                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionAmt1"
                                      value={values.table.commissionAmt1}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionRate1"
                                      value={values.table.commissionRate1}
                                      onChange={handleInputChange}
                                    />
                                </td>

                              </tr>
                            
                              <tr>
                                <th className = "commission-table-cls">
                                  Band 1 amount
                                </th>
                                <td className = "commission-table-cls">
                                  <Input className="search-cls"
                                      type="text" 
                                      name="minAmt2"
                                      value={values.table.minAmt2}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="maxAmt2"
                                      value={values.table.maxAmt2}
                                      onChange={handleInputChange}
                                    />
                                </td>


                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text"
                                      name="commissionAmt2" 
                                      value={values.table.commissionAmt2}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionRate2"
                                      value={values.table.commissionRate2}
                                      onChange={handleInputChange}
                                    />
                                </td>

                              </tr>
                            

                              <tr>
                                <th className = "commission-table-cls">
                                  Band 2 amount
                                </th>
                                <td className = "commission-table-cls">
                                  <Input className="search-cls"
                                      type="text"
                                      name="minAmt3"
                                      value={values.table.minAmt3}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="maxAmt3"
                                      value={values.table.maxAmt3}
                                      onChange={handleInputChange}
                                    />
                                </td>


                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionAmt3"
                                      value={values.table.commissionAmt3}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionRate3"
                                      value={values.table.commissionRate3}
                                      onChange={handleInputChange}
                                    />
                                </td>

                              </tr>
                            

                              <tr>
                                <th className = "commission-table-cls">
                                  Band 3 amount
                                </th>
                                <td className = "commission-table-cls">
                                  <Input className="search-cls"
                                      type="text" 
                                      name="minAmt4"
                                      value={values.table.minAmt4}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="maxAmt4"
                                      value={values.table.maxAmt4}
                                      onChange={handleInputChange}
                                    />
                                </td>


                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionAmt4"
                                      value={values.table.commissionAmt4}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionRate4"
                                      value={values.table.commissionRate4}
                                      onChange={handleInputChange}
                                    />
                                </td>

                              </tr>
                            

                              <tr>
                                <th className = "commission-table-cls">
                                  Max. Threshold
                                </th>
                                <td className = "commission-table-cls">
                                  <Input className="search-cls"
                                      type="text" 
                                      name="minAmt5"
                                      value={values.table.minAmt5}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="maxAmt5"
                                      value={values.table.maxAmt5}
                                      onChange={handleInputChange}
                                    />
                                </td>


                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionAmt5"
                                      value={values.table.commissionAmt5}
                                      onChange={handleInputChange}
                                    />
                                </td>

                                <td className = "commission-table-cls">
                                  <Input className="search-cls" 
                                      type="text" 
                                      name="commissionRate5"
                                      value={values.table.commissionRate5}
                                      onChange={handleInputChange}
                                    />
                                </td>

                              </tr>
                            

                            </tbody>
                           */}
                          </Table>
                          {errors.table && <div className="text-left text-danger">
                              <small>* {errors.table}</small>
                            </div>}
                        </Col>
                      </Row>
                    }
                  </div>


                  <Row className="mt-3">
                    <Col lg-12>
                      <div>
                        <Button
                          className="btn float-right"
                          color="primary" type="submit"
                          disabled={loading}
                        >
                          Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditCommission;