import React, { useEffect } from "react";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import moment from "moment";
import { getUserId, postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";

const SubscriberBalanceAnalysisReport = (props) => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [submitButton, setSubmitButton] = React.useState(false);
  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading]);

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading]);

  useEffect(() => {
    if (endDate && moment(new Date(endDate)).isBefore(new Date(startDate))) {
      setEndDate("");
    }
  }, [startDate]);

  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = "Please select start date";
    }
    if (!endDate) {
      error.endDate = "Please select end date";
    }
    return error;
  };

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  };

  const renameKey = (key) => {
    switch (key) {
      case "balance0":
        return "0";
      case "balance1to100":
        return ">Le 0.00 – Le 100.00";
      case "balance101to500":
        return ">Le 100.00 – Le 500.00";
      case "balance501to1000":
        return ">Le 500.00 – Le 1,000.00";
      case "balance1001to10000":
        return ">Le 1,000.00 – Le 10,000.00";
      case "balance10001to25000":
        return ">Le 10,000.00 – Le 25,000.00";
      case "balance25001to50000":
        return ">Le 25,000.00 – Le 50,000.00";
      case "balance50001to1lk":
        return ">Le 50,000.00 – Le 100,000.00";
      case "balance1lkplus":
        return ">Le 100,000.00";
      default:
        return;
    }
  };

  const resultFilter = (data) => {
    if (!data) return data;
    const list = [];
    Object.entries(data).forEach(([key, value], idx) => {
      if (typeof value === "object" && Array.isArray(value) && value?.length) {
        list.push({ name: renameKey(key), ...value[0] });
      }
    });
    const obj = {
      list,
      data,
    };
    return obj;
  };

  const fetchReport = () => {
    let errorMsg = "";
    setLoading(true);
    let id = getUserId();
    let data = {
      Status: "",
      PageNumber: Number(props.currentPage + 1),
      PerPage: pageSize,
    };

    postMethod(
      "/api/reports/SubscriberBalanceAnalysisReport",
      JSON.stringify(data),
      "post"
    )
      .then((res) => {
        setLoading(false);
        setIsMounted(true);
        setSubmitButton(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            props.parentCallback(resultFilter(res.data.result));
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsMounted(true);
        notifyFailed("Internal Server Error");
      });
  };

  const fetchReportData = () => {
    let errorMsg = "";
    let data = {
      Status: "",
      PageNumber: 1,
      PerPage: props.totalRec,
    };

    postMethod(
      "/api/reports/SubscriberBalanceAnalysisReport",
      JSON.stringify(data),
      "post"
    )
      .then((res) => {
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            props.parentReportData(resultFilter(res.data.result));
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
            props.errorReportData();
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.errorReportData();
        }
      })
      .catch((err) => {
        setIsMounted(true);
        notifyFailed("Internal Server Error");
        props.errorReportData();
      });
  };

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <Row>
        <Col lg="12">
          <Button
            color="primary"
            className="mt-3"
            onClick={onSubmitReport}
            disabled={loading}
          >
            Submit &nbsp; &nbsp;
            {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
          </Button>
        </Col>
      </Row>
      {/* &nbsp;&nbsp; */}
    </>
  );
};

export default SubscriberBalanceAnalysisReport;
