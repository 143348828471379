import React, { useEffect } from "react";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import moment from "moment";
import { getUserId, postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";

const AgentCommissionsReport = (props) => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [submitButton, setSubmitButton] = React.useState(false);
  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading]);

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading]);

  useEffect(() => {
    if (endDate && moment(new Date(endDate)).isBefore(new Date(startDate))) {
      setEndDate("");
    }
  }, [startDate]);

  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = "Please select start date";
    }
    if (!endDate) {
      error.endDate = "Please select end date";
    }
    return error;
  };

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  };

  const fetchReport = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      let errorMsg = "";
      setLoading(true);
      let id = getUserId();
      let data = {
        FromDate: moment(new Date(startDate)).format("DD/MM/YYYY"),
        ToDate: moment(new Date(endDate)).format("DD/MM/YYYY"),
        Status: "",
        PageNumber: Number(props.currentPage + 1),
        PerPage: pageSize,
      };

      if (props.sort && props.sort.sortPaymentTo == "1") {
        data["SortingOn"] = "PaymentTo";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentTo == "2") {
        data["SortingOn"] = "PaymentTo";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentFrom == "1") {
        data["SortingOn"] = "PaymentFrom";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentFrom == "2") {
        data["SortingOn"] = "PaymentFrom";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortReferenceId == "1") {
        data["SortingOn"] = "ReferenceId";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortReferenceId == "2") {
        data["SortingOn"] = "ReferenceId";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortProductId == "1") {
        data["SortingOn"] = "ProductId";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortProductId == "2") {
        data["SortingOn"] = "ProductId";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortCreatedAt == "1") {
        data["SortingOn"] = "CreatedAt";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortCreatedAt == "2") {
        data["SortingOn"] = "CreatedAt";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data["SortingOn"] = "Amount";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data["SortingOn"] = "Amount";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data["SortingOn"] = "FeesAmount";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data["SortingOn"] = "FeesAmount";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortStatus == "1") {
        data["SortingOn"] = "Status";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortStatus == "2") {
        data["SortingOn"] = "Status";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentMethod == "1") {
        data["SortingOn"] = "PaymentMethod";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentMethod == "2") {
        data["SortingOn"] = "PaymentMethod";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentType == "1") {
        data["SortingOn"] = "PaymentType";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentType == "2") {
        data["SortingOn"] = "PaymentType";
        data["IsDescending"] = true;
      }

      postMethod(
        "/api/reports/GetTransactionsReport",
        JSON.stringify(data),
        "post"
      )
        .then((res) => {
          setLoading(false);
          setIsMounted(true);
          setSubmitButton(false);
          if (res && res.data) {
            if (res.data.responseCode === "200" && res.data.result) {
              // Success
              if (!res.data.result.list || !res.data.result.list.length) {
                res.data.result.list = [];
              }
              props.parentCallback(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || "Error in fetching list";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in fetching";
              notifyFailed(errorMsg);
            }
          } else {
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsMounted(true);
          notifyFailed("Internal Server Error");
        });
    }
  };

  const fetchReportData = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      let errorMsg = "";
      let data = {
        FromDate: moment(new Date(startDate)).format("DD/MM/YYYY"),
        ToDate: moment(new Date(endDate)).format("DD/MM/YYYY"),
        Status: "",
        PageNumber: 1,
        PerPage: props.totalRec,
      };

      if (props.sort && props.sort.sortPaymentTo == "1") {
        data["SortingOn"] = "PaymentTo";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentTo == "2") {
        data["SortingOn"] = "PaymentTo";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentFrom == "1") {
        data["SortingOn"] = "PaymentFrom";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentFrom == "2") {
        data["SortingOn"] = "PaymentFrom";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortReferenceId == "1") {
        data["SortingOn"] = "ReferenceId";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortReferenceId == "2") {
        data["SortingOn"] = "ReferenceId";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortProductId == "1") {
        data["SortingOn"] = "ProductId";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortProductId == "2") {
        data["SortingOn"] = "ProductId";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortCreatedAt == "1") {
        data["SortingOn"] = "CreatedAt";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortCreatedAt == "2") {
        data["SortingOn"] = "CreatedAt";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data["SortingOn"] = "Amount";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data["SortingOn"] = "Amount";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data["SortingOn"] = "FeesAmount";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data["SortingOn"] = "FeesAmount";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortStatus == "1") {
        data["SortingOn"] = "Status";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortStatus == "2") {
        data["SortingOn"] = "Status";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentMethod == "1") {
        data["SortingOn"] = "PaymentMethod";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentMethod == "2") {
        data["SortingOn"] = "PaymentMethod";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortPaymentType == "1") {
        data["SortingOn"] = "PaymentType";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortPaymentType == "2") {
        data["SortingOn"] = "PaymentType";
        data["IsDescending"] = true;
      }

      postMethod(
        "/api/reports/GetTransactionsReport",
        JSON.stringify(data),
        "post"
      )
        .then((res) => {
          setIsMounted(true);
          if (res && res.data) {
            if (res.data.responseCode === "200" && res.data.result) {
              // Success
              if (!res.data.result.list || !res.data.result.list.length) {
                res.data.result.list = [];
              }
              props.parentReportData(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || "Error in fetching list";
              notifyFailed(errorMsg);
              props.errorReportData();
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in fetching";
              notifyFailed(errorMsg);
              props.errorReportData();
            }
          } else {
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        })
        .catch((err) => {
          setIsMounted(true);
          notifyFailed("Internal Server Error");
          props.errorReportData();
        });
    }
  };

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <Row>
        <Col className={errors.startDate || errors.endDate ? "mt-3" : ""}>
          <Row>
            <Col lg="12">
              <small>
                <strong>Start Date</strong>
              </small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup className="mb-0">
                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="startDate"
                    placeholder="Start Date"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Input>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {errors.startDate && (
                <div className="text-left text-danger">
                  <small>* {errors.startDate}</small>
                </div>
              )}
            </Col>
          </Row>
        </Col>

        <Col className={errors.endDate || errors.startDate ? "mt-3" : ""}>
          <Row>
            <Col lg="12">
              <small>
                <strong>End Date</strong>
              </small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup className="mb-0">
                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="endDate"
                    placeholder="End Date"
                    min={startDate}
                    max={moment().format("YYYY-MM-DD")}
                    disabled={!startDate}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Input>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {errors.endDate && (
                <div className="text-left text-danger">
                  <small>* {errors.endDate}</small>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <Row>
        <Col lg="12">
          <Button
            color="primary"
            className="mt-3"
            onClick={onSubmitReport}
            disabled={loading}
          >
            Submit &nbsp; &nbsp;
            {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
          </Button>
        </Col>
      </Row>
      {/* &nbsp;&nbsp; */}
    </>
  );
};

export default AgentCommissionsReport;
