import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Table,
  Badge,
} from "reactstrap";
// core components

import { useToasts } from "react-toast-notifications";
import Header from "components/Headers/Header";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { collBankStatus } from "appConfig";
import { getMethod } from "services/httpServices";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { postMethod } from "services/httpServices";

const initialValue = [
  { name: "Keanu Reeves", profession: "Actor" },
  { name: "Lionel Messi", profession: "Football Player" },
  { name: "Cristiano Ronaldo", profession: "Football Player" },
  { name: "Jack Nicklaus", profession: "Golf Player" },
];

const STATUS = collBankStatus;

const EDSATransactionsMerchant = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [error, setError] = React.useState(null);
  const [response, setResponse] = React.useState({});

  const { addToast } = useToasts();

  const [people, setPeople] = React.useState(initialValue);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const submit = () => {
    if (!searchText) {
      setError("Please Enter Transaction ID");
      setLoading(false);
      setResponse({});
    } else {
      setError(null);
      setLoading(true);
      setResponse({});
      fetchTrans(searchText);
    }
  };

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? ob.value : val;
  };

  const sendNotification = async (id) => {
    setLoading(true);
    let errorMsg = "";
    postMethod(
      "/api/Transactions/SendNotificationEDSATransaction?transactionId=" + id,
      {},
      "post"
    )
      .then((res) => {
        setLoading(false);
        notifySuccess("Email and SMS sent again successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(errorMsg);
        notifyFailed("Try Again After Sometime");
      });
  };

  const fetchTrans = async (id) => {
    let errorMsg = "";
    getMethod("/api/Transactions/CheckEDSATransaction/" + id, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          // Success
          if (!res.data.content) {
            setError("No transaction found");
          } else {
            setResponse(res.data.content);
          }
        } else {
          errorMsg = "Unknown Error";
          setError(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(errorMsg);
      });
  };

  const getAmt = (amount) => {
    let amt = Number(amount);
    return amt?.toLocaleString();
  };

  const reset = () => {
    setLoading(false);
    setResponse({});
    setSearchText("");
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col xl="8">
            <Card className="bg-secondary shadow">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CardHeader className="border-0 d-flex justify-content-center card-header-border">
                  <Form
                    className="form-inline mr-3 d-md-flex ml-lg-auto mt-4 mb-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup className="mb-0 navbar-search ">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="search-cls"
                          placeholder="Enter Transaction ID"
                          type="text"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    &nbsp; &nbsp;
                    <Button color="primary" onClick={reset} disabled={loading}>
                      Reset
                    </Button>
                    <Button color="primary" onClick={submit} disabled={loading}>
                      Submit
                    </Button>
                  </Form>
                </CardHeader>
                <CardBody className="search-trans-cls">
                  {loading && <FullPageLoader />}
                  <div>
                    {error && (
                      <div className="text-center text-warning">{error}</div>
                    )}

                    {response && response?.transactionID && (
                      <div>
                        <Row className="mb-4">
                          <Col lg="12" className="text-center mt-4">
                            <strong>EDSA Transaction Details</strong>
                          </Col>
                        </Row>
                        <div
                          className="border"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div className="p-2">
                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Transaction ID :</strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small>{response.transactionID}</small>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Meter Number: </strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small>{response.meterNumber}</small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Units :</strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small>{response.units}</small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Voucher PIN :</strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small> {response.voucherPin}</small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Amount :</strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small>
                                  Le {getAmt(response.denomination)}
                                </small>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="5">
                                <small>
                                  <strong>Status :</strong>
                                </small>
                              </Col>
                              <Col lg="7">
                                <small>
                                  {statusFormat(response.statusDescription)}
                                </small>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <Row className="mb-4">
                          <Col lg="12" className="text-center mt-4">
                            <Button
                              color="primary"
                              onClick={() =>
                                sendNotification(response.transactionID)
                              }
                              disabled={loading}
                            >
                              Send Notification
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EDSATransactionsMerchant;