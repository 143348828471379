import React, { useEffect } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import {
  Table
} from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader } from "services/util";
import { btnCss, iconCss } from '../../appConfig';
import FullPageLoader from "components/FullPageLoader/fullpageloader";

const sortIS = {
  sortCreatedat: 0,
  sortMerchantName: 0,
  sortAmount: 0,
  sortFeesAmount: 0
};

const reportloaderContainer = {
  position: 'absolute',
  top: '280px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
};

const MerchantCommissionTable = (props) => {

  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sort, setSort] = React.useState(sortIS);

  /**========================== Redux Hook ====================== */

  useEffect(()=> {
    setFilteredList(props.datalist)
  }, [props.datalist]);

  useEffect(()=> {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(()=> {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(()=> {
    props.setParentSort(sort);
  }, [sort]);

  /**========================== File Function ====================== */

  const getAmt = (amt) => {
    return amt?.toLocaleString();
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  return (
    <>
    <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col"
              onClick={() => sortHandle(sort.sortCreatedat, 'sortCreatedat')}>
              Instruction Date/Time
              <button style={btnCss} >
                <i className={getSortCls(sort.sortCreatedat)}
                  onClick={() => sortHandle(sort.sortCreatedat, 'sortCreatedat')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}>
              Merchant Name
              <button style={btnCss} >
                <i className={getSortCls(sort.sortMerchantName)}
                  onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
              Transaction Amount
              <button style={btnCss} >
                <i className={getSortCls(sort.sortAmount)}
                  onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')}>
              Commission Amount
              <button style={btnCss} >
                <i className={getSortCls(sort.sortFeesAmount)}
                  onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')}
                />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
        {filteredList.length > 0 && filteredList.map((mmo, i) => {
          return (
            <tr>
              <td scope="row">
                {moment(mmo.createdAt).format('lll')}
              </td>
              <td>{mmo.userName}</td>
              <td>{getAmt(mmo.paymentAmount)}</td>
              <td>{getAmt(mmo.commissionAmount)}</td>
            </tr>

          )
        }
        )}
        </tbody>
        {props.loading && 
          <FullPageLoader label={"Fetching..."} 
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
        {!props.loading && !filteredList.length && 
          <NoRecordFound 
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
      </Table> 
    </>
  );
}
export default MerchantCommissionTable;